import React, { useEffect, useState } from 'react';
import { FaUsers, FaDollarSign, FaBicycle, FaClipboardCheck } from 'react-icons/fa';
import { useURL } from '../contexts/URLContext';

// /api/v1/total/total_pay
// /api/v1/total/total_days

const CardData = () => {
    const backendUrl = useURL();
    const [totalBikes, setTotalBikes] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalDays, setTotalDays] = useState(0);

    // Fetch the total bikes, users, and revenue
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch the total number of bikes
                const bikesResponse = await fetch(`${backendUrl}/api/v1/totalbikes`);
                const bikesData = await bikesResponse.json();
                setTotalBikes(bikesData.totalBikes);

                // Fetch the total number of users with status 'finished' (X)
                const usersResponse = await fetch(`${backendUrl}/api/v1/userNumber/X`);
                const usersData = await usersResponse.json();
                setTotalUsers(usersData.count);

                // Fetch the total revenue
                const revenueResponse = await fetch(`${backendUrl}/api/v1/total/total_pay`);
                const revenueData = await revenueResponse.json();
                console.log(revenueData);
                setTotalRevenue(revenueData.total);

                // Fetch the total revenue
                const daysResponse = await fetch(`${backendUrl}/api/v1/total/total_days`);
                const daysData = await daysResponse.json();
                console.log(daysData);
                setTotalDays(daysData.total);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [backendUrl]);

    // CardDataStats Component
    const CardDataStats = ({ title, total, icon }) => {
        return (
            <div className="p-6 rounded-lg bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-800 shadow-sm hover:shadow-md transition-all duration-200">
            <div className="flex items-start gap-4">
              <div className="p-3 rounded-lg bg-blue-50 dark:bg-blue-900/20">
                {icon}
              </div>
              <div className="flex flex-col gap-1">
                <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400">
                  {title}
                </h3>
                <p className="text-2xl font-semibold text-gray-900 dark:text-white">
                  {total}
                </p>
              </div>
            </div>
          </div>
        );
    };

    return (
        <div className="w-full max-w-screen-xl mx-auto">
            {/* Card Section */}
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-4">


                {/* Card 1: Total Bikes  */}
                <CardDataStats
                    title="Bikes"
                    total={totalBikes}
                    icon={<FaBicycle className="text-4xl text-yellow-500" />}
                />

                {/* Card 2: Serviced Customers*/}
                <CardDataStats
                    title="Customers"
                    total={`${totalUsers}`}
                    icon={<FaUsers className="text-4xl text-blue-500" />}
                />

                {/* Card 3: Total Days Of Rentals */}
                <CardDataStats
                    title="Days of Rental"
                    total={`${totalDays}`}
                    icon={<FaClipboardCheck className="text-4xl text-cyan-500" />}
                />
                
                {/* Card 4: Total Rvenue */}
                <CardDataStats
                    title="Total Revenue"
                    total={`${totalRevenue}₱`}
                    icon={<FaDollarSign className="text-4xl text-green-500" />}
                />

            </div>
        </div>
    );
};

export default CardData;
