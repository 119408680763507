import React, { useState, useEffect } from 'react';
import Calendar from './Calendar';
import { useURL } from '../contexts/URLContext';

//     // const events = [
//     //     { name: 'BIKE 5', family: 'BIKE', startDate: new Date(2024, 8, 4), endDate: new Date(2024, 8, 22), bike: 5 },
//     //     { title: 'Event 1', startDate: new Date(2024, 8, 10), endDate: new Date(2024, 8, 12), bike: 1 },
//     //     { title: 'Event 2', startDate: new Date(2024, 8, 15), endDate: new Date(2024, 8, 15), bike: 2 },
//     //     { title: 'BIKE 1', startDate: new Date(2024, 8, 20), endDate: new Date(2024, 8, 27), bike: 1 },
//     //     { title: 'BIKE 2', startDate: new Date(2024, 8, 20), endDate: new Date(2024, 8, 22), bike: 2 },
//     //     { title: 'BIKE 3', startDate: new Date(2024, 8, 22), endDate: new Date(2024, 8, 22), bike: 3 },
//     //     { title: 'BIKE 4', startDate: new Date(2024, 8, 22), endDate: new Date(2024, 8, 22), bike: 4 },

const Events = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const backendURL = useURL();
    
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const url = `${backendURL}/api/v1/data?tableName=revenue`;
                console.log(url);
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                
                // Transform the API data to match the Calendar component's expected format
                const transformedEvents = data.map(event => ({
                    name: event.name,
                    family: event.family,  // Adjust if needed to match the Calendar's expectations
                    startDate: new Date(event.start_date),
                    endDate: new Date(event.end_date),
                    bike: event.bike
                }));
                
                setEvents(transformedEvents);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, [backendURL]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <Calendar events={events} />
        </div>
    );
};

export default Events;
