import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const DiagramChart = () => {
  const [state, setState] = useState({
    series: [
      {
        name: 'Sales',
        data: [44, 55, 41, 67, 22, 43, 65],
      },
      {
        name: 'Revenue',
        data: [13, 23, 20, 8, 13, 27, 15],
      },
    ],
  });

  const options = {
    colors: ['#3C50E0', '#80CAEE'],
    chart: {
      fontFamily: 'Satoshi, sans-serif',
      type: 'bar',
      height: 335,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: 'var(--tw-text-color)', // Adjust for light/dark mode
    },
    responsive: [
      {
        breakpoint: 1536,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
              columnWidth: '25%',
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: '25%',
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontFamily: 'Satoshi',
      fontWeight: 500,
      fontSize: '14px',
      markers: {
        radius: 99,
      },
      labels: {
        colors: 'var(--tw-text-color)', // Dynamic for light/dark mode
      },
    },
    grid: {
      borderColor: 'var(--tw-border-color)', // Dynamic border color
    },
    fill: {
      opacity: 1,
    },
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    // Update chart data dynamically if needed
    console.log('Selected Time Period:', selectedValue);
  };

  return (
      <div className="col-span-12 rounded-lg border border-stroke bg-white p-9 shadow-sm xl:col-span-6 dark:border-0 dark:bg-gray-800">

      {/* Header Section */}
      <div className="flex items-center justify-between mb-5">
        <div>
          <h4 className="text-lg font-semibold text-black dark:text-white">
            Profit This Week
          </h4>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Weekly sales and revenue summary
          </p>
        </div>
        <div>
          <div className="relative">
            <select
              name="time-period"
              id="time-period"
              className="appearance-none rounded-md border border-gray-300 bg-gray-50 py-1.5 px-3 text-sm font-medium text-gray-700 shadow-sm outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300"
              onChange={handleDropdownChange}
            >
              <option value="this-week">This Week</option>
              <option value="last-week">Last Week</option>
            </select>
            <span className="absolute top-1/2 right-3 -translate-y-1/2 pointer-events-none">
              <svg
                width="12"
                height="6"
                viewBox="0 0 12 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6 5L11 1"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      {/* Chart Section */}
      <div>
        <ReactApexChart
          options={options}
          series={state.series}
          type="bar"
          height={335}
        />
      </div>
    </div>
  );
};

export default DiagramChart;
