// import React, { useMemo, useEffect } from "react";
// import { useTable, useSortBy } from "react-table";
// import { format, isValid } from 'date-fns';

// // let tables = [{ revenu: name, start_date, end_date, bike, active },
// //     {bikes: bikes_number, plate, date_bought},
// //     {expence: Description, total_cost, date_of_purchase}

// // ]

// const DisplayTable = ({ data, tableName, onRowSelect }) => {
//     const maxStatusText = ['Active', 'Pending', 'Finished'].reduce((max, text) => text.length > max.length ? text : max, '');

//     const columns = useMemo(() => {
//         // Clear columns if no data is available
//         if (data.length === 0) {
//             return [];
//         }

//         // Define the 'Active' column separately
//         const activeColumn = {
//             Header: "Status",
//             accessor: 'active',
//             Cell: ({ value }) => {
//                 let statusText = 'Unknown';
//                 let statusColor = 'bg-gray-200 bg-opacity-80 text-gray-800 text-opacity-80 dark:bg-gray-700 dark:bg-opacity-40 dark:text-gray-300 dark:text-opacity-80';
//                 const maxWidth = `${maxStatusText.length * 0.75}rem`;

//                 switch (value) {
//                     case 'V':
//                         statusText = 'Active';
//                         statusColor = 'bg-green-200 bg-opacity-80 text-green-800 text-opacity-80 dark:bg-green-700 dark:bg-opacity-40 dark:text-green-300 dark:text-opacity-80';
//                         break;
//                     case 'P':
//                         statusText = 'Pending';
//                         statusColor = 'bg-yellow-200 bg-opacity-80 text-yellow-800 text-opacity-80 dark:bg-yellow-700 dark:bg-opacity-40 dark:text-yellow-300 dark:text-opacity-80';
//                         break;
//                     case 'X':
//                         statusText = 'Finished';
//                         statusColor = 'bg-red-200 bg-opacity-80 text-red-800 text-opacity-80 dark:bg-red-700 dark:bg-opacity-40 dark:text-red-300 dark:text-opacity-80';
//                         break;
//                     default:
//                         statusText = 'Unknown';
//                         statusColor = 'bg-gray-200 bg-opacity-80 text-gray-800 text-opacity-80 dark:bg-gray-700 dark:bg-opacity-50 dark:text-gray-300 dark:text-opacity-80';
//                         break;
//                 }

//                 return (
//                     <span
//                         className={`px-2.5 py-1 rounded-full font-semibold ${statusColor}`}
//                         style={{ minWidth: maxWidth, display: 'inline-block' }}
//                     >
//                         • {statusText}
//                     </span>
//                 );
//             },
//         };

//         // Custom column format for the 'revenue' table
//         if (tableName === "revenue") {
//             return [
//                 {
//                     Header: "Name",
//                     accessor: row => {
//                         const name = row.name || '';
//                         const family = row.family || '';
//                         return `${name}, ${family}`.trim();
//                     },
//                     id: 'name_family',
//                 },
//                 {
//                     Header: "Dates Requested",
//                     accessor: row => {
//                         const startDate = row.start_date ? new Date(row.start_date.split(' ')[0]) : null;
//                         const endDate = row.end_date ? new Date(row.end_date.split(' ')[0]) : null;

//                         if (!isValid(startDate) || !isValid(endDate)) {
//                             return 'N/A';
//                         }

//                         const formattedStart = format(startDate, 'MMM d');
//                         const formattedEnd = format(endDate, 'MMM d');
//                         const year = format(startDate, 'yyyy');
//                         return `${formattedStart} - ${formattedEnd}, ${year}`;
//                     },
//                     id: 'dates_requested',
//                 },
//                 ...Object.keys(data[0])
//                     .filter(key => !['name', 'family', 'start_date', 'end_date', 'active', 'passport', 'deposit', 'km_start', 'km_finish'].includes(key))
//                     .map(key => ({
//                         Header: key.charAt(0).toUpperCase() + key.slice(1),
//                         accessor: key,
//                     })),
//                 activeColumn
//             ];
//         }

//         // Default column logic for other table names
//         return [
//             ...Object.keys(data[0])
//                 .filter(key => !['passport', 'deposit', 'km_start', 'km_finish'].includes(key))
//                 .map(key => ({
//                     Header: key.charAt(0).toUpperCase() + key.slice(1),
//                     accessor: key,
//                     Cell: ({ value }) => {
//                         if (key === 'start_date' || key === 'end_date' || key === 'date' || key === 'date_of_purchase' || key === 'date_bought') {
//                             return typeof value === 'string' ? value.split(' ')[0] : value;
//                         }
//                         return value;
//                     }
//                 }))
//         ];
//     }, [data, tableName]);

//     const {
//         getTableProps,
//         getTableBodyProps,
//         headerGroups,
//         rows,
//         prepareRow,
//     } = useTable(
//         { columns, data },
//         useSortBy
//     );

//     // Clear rows and columns when tableName changes
//     useEffect(() => {
//         if (data.length === 0) {
//             console.log("No data available for the new table");
//         }
//     }, [data, tableName]);

//     return (
//         <div className="overflow-x-auto">
//             {data.length === 0 ? (
//                 <div className="text-center p-4">No data available for this table.</div>
//             ) : (
//                 <table {...getTableProps()} className="table-auto w-full min-w-max dark:bg-gray-800 dark:text-white bg-white text-gray-800">
//                     <thead>
//                         {headerGroups.map(headerGroup => (
//                             <tr {...headerGroup.getHeaderGroupProps()}>
//                                 {headerGroup.headers
//                                     .filter(column => column.id !== 'id')
//                                     .map(column => (
//                                         <th
//                                             {...column.getHeaderProps(column.getSortByToggleProps())}
//                                             className="p-2 text-center dark:bg-gray-700 dark:text-gray-100 text-gray-600 cursor-pointer"
//                                         >
//                                             {column.render('Header').replace(/_/g, ' ')}
//                                             <span>
//                                                 {column.isSorted
//                                                     ? column.isSortedDesc
//                                                         ? ' 🔽'
//                                                         : ' 🔼'
//                                                     : ''}
//                                             </span>
//                                         </th>
//                                     ))}
//                             </tr>
//                         ))}
//                     </thead>
//                     <tbody {...getTableBodyProps()}>
//                         {rows.map(row => {
//                             prepareRow(row);
//                             return (
//                                 <tr {...row.getRowProps()}
//                                     className="border-t border-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer transition-colors duration-300"
//                                     onClick={() => onRowSelect(row.original)}
//                                 >
//                                     {row.cells
//                                         .filter(cell => cell.column.id !== 'id')
//                                         .map(cell => (
//                                             <td {...cell.getCellProps()} className="p-2 text-center dark:bg-gray-700 dark:text-gray-200 bg-white text-gray-800">
//                                                 {cell.render('Cell')}
//                                             </td>
//                                         ))}
//                                 </tr>
//                             );
//                         })}
//                     </tbody>
//                 </table>
//             )}
//         </div>
//     );
// };

// export default DisplayTable;


import React, { useMemo, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import { format, isValid } from 'date-fns';

const DisplayTable = ({ data, tableName, onRowSelect }) => {
    const maxStatusText = ['Active', 'Pending', 'Finished'].reduce((max, text) => text.length > max.length ? text : max, '');

    // Define which columns to show for mobile/tablet (md and below)
    const mobileColumns = {
        revenue: ['name', 'start_date', 'end_date', 'bike', 'active'],
        bikes: ['bike_number', 'plate', 'date_bought'],
        expence: ['description', 'total_cost', 'date_of_purchase'],
        maintenance: ['bike', 'type', 'date'],
    };

    // Custom style for status cell to prevent overflow
    const getStatusStyle = (maxWidth) => ({
        minWidth: maxWidth,
        display: 'inline-block',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    });

    const columns = useMemo(() => {
        if (data.length === 0) return [];


        const activeColumn = {
            Header: "Status",
            accessor: 'active',
            Cell: ({ value }) => {
                let statusText = '';
                let statusColor = 'bg-gray-200 bg-opacity-80 text-gray-800 text-opacity-80 dark:bg-gray-700 dark:bg-opacity-40 dark:text-gray-300 dark:text-opacity-80';

                switch (value) {
                    case 'V':
                        statusText = 'Active';
                        statusColor = 'bg-green-200 bg-opacity-80 text-green-800 text-opacity-80 dark:bg-green-700 dark:bg-opacity-40 dark:text-green-300 dark:text-opacity-80';
                        break;
                    case 'P':
                        statusText = 'Pending';
                        statusColor = 'bg-yellow-200 bg-opacity-80 text-yellow-800 text-opacity-80 dark:bg-yellow-700 dark:bg-opacity-40 dark:text-yellow-300 dark:text-opacity-80';
                        break;
                    case 'X':
                        statusText = 'Finished';
                        statusColor = 'bg-red-200 bg-opacity-80 text-red-800 text-opacity-80 dark:bg-red-700 dark:bg-opacity-40 dark:text-red-300 dark:text-opacity-80';
                        break;
                    default:
                        statusText = 'Unknown';
                        statusColor = 'bg-gray-200 bg-opacity-80 text-gray-800 text-opacity-80 dark:bg-gray-700 dark:bg-opacity-50 dark:text-gray-300 dark:text-opacity-80';
                }

                return (
                    <span
                        className={`px-2 py-1 rounded-full font-semibold ${statusColor} text-xs sm:text-sm`}
                        style={{ minWidth: '1.5rem', display: 'inline-block' }}
                    >
                        •
                        <span className="hidden sm:inline"> {statusText}</span>
                    </span> 
                );
            },
        };

        if (tableName === "revenue") {
            const allColumns = [
                {
                    Header: "Name",
                    accessor: row => {
                        const name = row.name || '';
                        const family = row.family || '';
                        return `${name}, ${family}`.trim();
                    },
                    id: 'name_family',
                    className: 'hidden md:table-cell max-w-[120px] truncate'
                },
                {
                    Header: "Name",
                    // accessor: 'name',
                    accessor: row => {
                        const name = row.name || '';
                        return name.split(' ')[0]; // Only keep text before the first space
                    },
                    className: 'md:hidden max-w-[100px] truncate'
                },
                {
                    Header: "Dates",
                    accessor: row => {
                        const startDate = row.start_date ? new Date(row.start_date.split(' ')[0]) : null;
                        const endDate = row.end_date ? new Date(row.end_date.split(' ')[0]) : null;

                        if (!isValid(startDate) || !isValid(endDate)) return 'N/A';

                        const formattedStart = format(startDate, 'MMM d');
                        const formattedEnd = format(endDate, 'MMM d');
                        const year = format(startDate, 'yyyy');
                        return `${formattedStart} - ${formattedEnd}, ${year}`;
                    },
                    id: 'dates_requested',
                    className: 'max-w-[130px] truncate'
                },
                ...Object.keys(data[0])
                    .filter(key => !['name', 'family', 'start_date', 'end_date', 'active', 'passport', 'deposit', 'km_start', 'km_finish'].includes(key))
                    .map(key => ({
                        Header: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
                        accessor: key,
                        className: `${mobileColumns.revenue.includes(key) ? '' : 'hidden md:table-cell'} max-w-[100px] truncate`
                    })),
                {
                    ...activeColumn,
                    className: `${mobileColumns.revenue.includes('active') ? '' : 'hidden md:table-cell'} max-w-[100px]`
                }
            ];

            return allColumns;
        }

        // For other tables
        return Object.keys(data[0])
            .filter(key => !['passport', 'deposit', 'km_start', 'km_finish'].includes(key))
            .map(key => ({
                Header: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
                accessor: key,
                Cell: ({ value }) => {
                    if (key.includes('date')) {
                        return typeof value === 'string' ? format(new Date(value.split(' ')[0]), 'MMM d, yyyy') : value;
                    }
                    return value;
                },
                className: `${mobileColumns[tableName]?.includes(key) ? '' : 'hidden md:table-cell'} max-w-[100px] truncate`
            }));
    }, [data, tableName]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        { columns, data },
        useSortBy
    );


    return (
        <div className="w-full overflow-x-auto">
            {data.length === 0 ? (
                <div className="text-center p-4">No data available for this table.</div>
            ) : (
                <div className="inline-block min-w-full align-middle">
                    <table
                        {...getTableProps()}
                        className="w-full table-auto text-xs sm:text-sm md:text-base dark:bg-gray-800 dark:text-white bg-white text-gray-800"
                    >
                        <thead className="bg-gray-50 dark:bg-gray-700">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers
                                        .filter(column => column.id !== 'id')
                                        .map(column => (
                                            <th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={`py-2 text-center font-semibold text-gray-600 dark:text-gray-200 ${column.className || ''}`}
                                            >
                                                <div className="truncate">
                                                    {column.render('Header')}
                                                    {column.isSorted ? (
                                                        <span>
                                                            {column.isSortedDesc ? ' 🔽' : ' 🔼'}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </th>
                                        ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="border-t border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer transition-colors duration-300"
                                        onClick={() => onRowSelect(row.original)}
                                    >
                                        {row.cells
                                            .filter(cell => cell.column.id !== 'id')
                                            .map(cell => (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className={`p-1 sm:p-2 text-center whitespace-nowrap dark:bg-gray-700 dark:text-gray-200 bg-white text-gray-800 ${cell.column.className || ''}`}
                                                >
                                                    <div className="truncate">
                                                        {cell.render('Cell')}
                                                    </div>
                                                </td>
                                            ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );

};

export default DisplayTable;