import React, { useState, useEffect } from 'react';
import { useURL } from '../contexts/URLContext';
import { FaBars, FaTimes } from 'react-icons/fa';

const TableMenu = React.memo(({ updateSelectedTable, selectedTable, loading, menuOpen, toggleMenu }) => {
    const backendURL = useURL();
    const [tables, setTables] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTables = async () => {
            try {
                const uri = `${backendURL}/api/v1/tables`;
                const response = await fetch(uri);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setTables(data);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        fetchTables();
    }, [backendURL]);

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="relative">
            {/* Hamburger menu for small screens */}            
            <div className="flex justify-between items-center p-5 bg-gray-50 dark:bg-gray-800 rounded-t-md md:hidden">
                {/* Table name */}
                <span className="text-gray-700 dark:text-gray-300 font-bold">
                    {selectedTable || 'Select Table'}
                </span>

                {/* Hamburger Icon */}
                <button onClick={toggleMenu} className="text-gray-700 ml-3 dark:text-gray-300 focus:outline-none">
                    {menuOpen ? <FaTimes size={28} /> : <FaBars size={28} />}
                </button>

                {/* Underline */}
                <span
                    className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 w-3/4 h-1 rounded-t-lg transition-all duration-300
                bg-teal-500 dark:bg-teal-400`}
                ></span>
            </div>



            {/* Menu items for small screens */}
            {menuOpen && (
                <div className="absolute top-full left-0 right-0 bg-white dark:bg-zinc-800 shadow-lg rounded-lg z-50 md:hidden">
                    <div className="flex flex-col">
                        {tables
                            .filter((table) => table !== selectedTable) // Exclude the selected table
                            .map((table) => (
                                <button
                                    key={table}
                                    onClick={() => {
                                        if (selectedTable !== table) {
                                            updateSelectedTable(table);
                                            toggleMenu(); // Close menu after selecting
                                        }
                                    }}
                                    className={`relative w-full text-left text-md font-semibold p-5 px-2 transition-all duration-300 transform 
                                        hover:text-teal-900 dark:hover:text-teal-300 
                                        hover:bg-gray-100 dark:hover:bg-zinc-700
                                        hover:underline rounded-sm
                                        cursor-pointer focus:outline-none
                                    ${selectedTable === table
                                            ? 'text-teal-500 dark:text-teal-400'
                                            : 'text-gray-700 dark:text-teal-100 hover:text-teal-900 dark:hover:text-teal-300'}`}
                                >
                                    {table}
                                    <span
                                        className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full h-1 rounded-t-lg transition-all duration-300
                                        ${selectedTable === table ? 'bg-teal-500 dark:bg-teal-400' : 'bg-transparent'}
                                    `}
                                    ></span>
                                </button>
                            ))}
                    </div>
                </div>
            )}

            {/* Inline menu for large screens */}
            <div className="hidden md:flex space-x-4 p-4 rounded-lg">
                {tables.map((table) => (
                    <button
                        key={table}
                        onClick={() => updateSelectedTable(table)}
                        className={`relative text-lg font-semibold py-2 px-4 transition-all duration-300 
                            ${selectedTable === table
                                ? 'text-teal-500 dark:text-teal-400'
                                : 'text-gray-700 dark:text-teal-100 hover:text-teal-900 dark:hover:text-teal-300'}`}
                    >
                        {table}
                        <span
                            className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full h-1 rounded-t-lg transition-all duration-300
                                ${selectedTable === table ? 'bg-teal-500 dark:bg-teal-400' : 'bg-transparent'}
                            `}
                        ></span>
                    </button>
                ))}
            </div>
        </div>
    );
});

export default TableMenu;
