/**
 * MainTable Component
 * 
 * A comprehensive table component that handles data display, searching, and management
 * for different database tables. Features include:
 * - Responsive design with mobile-friendly search
 * - Dynamic table switching
 * - Real-time search functionality
 * - Dark mode support
 * - Row selection and detail view
 * - Add new row capability
 * 
 * @component
 * @author [RazBux]
 * @lastModified 2024-02-22
 */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash.debounce';
import DetailCard from './card/DetailCard';
import SearchInput from './SearchInput';
import DisplayTable from './DisplayTable';
import TableMenu from './TableMenu';
import AddDbRow from './card/AddRow';
import { useURL } from '../contexts/URLContext';
import MotorbikeLoader from './Animation/MotorbikeLoader';
import { FaSearch, FaTimes, FaPlus } from 'react-icons/fa';

const MainTable = () => {
    // ================ State Management ================
    // Core table states
    const [tableName, setTableName] = useState('revenue');
    const [columnName, setColumnName] = useState('');
    const [searchParams, setSearchParams] = useState({ id: '' });
    const [searchResults, setSearchResults] = useState([]);
    
    // UI state management
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [showAddDbRow, setShowAddDbRow] = useState(false);
    
    // Row selection states
    const [selectedRow, setSelectedRow] = useState(null);
    const [showDetails, setShowDetails] = useState(false);

    // ================ Refs ================
    // Used to prevent race conditions in async operations
    const currentTableRef = useRef(tableName);
    // Used for click-outside detection
    const menuRef = useRef(null);
    const searchContainerRef = useRef(null);
    const searchInputRef = useRef(null);

    // Get backend URL from context
    const backendURL = useURL();

    // ================ Effects ================

    /**
     * Handles click-outside detection for the menu
     */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        if (menuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuOpen]);

    /**
     * Handles click-outside detection for the search component
     */
    useEffect(() => {
        const handleSearchClickOutside = (event) => {
            if (searchContainerRef.current && 
                !searchContainerRef.current.contains(event.target)) {
                setIsSearchVisible(false);
            }
        };

        if (isSearchVisible) {
            document.addEventListener('mousedown', handleSearchClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleSearchClickOutside);
        };
    }, [isSearchVisible]);

    /**
     * Auto-focuses the search input when it becomes visible
     */
    useEffect(() => {
        if (isSearchVisible && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isSearchVisible]);

    /**
     * Updates the currentTableRef when tableName changes
     * This prevents race conditions in async operations
     */
    useEffect(() => {
        currentTableRef.current = tableName;
    }, [tableName]);

    // ================ API Calls ================

    /**
     * Fetches table data from the backend
     * @param {string} table - The name of the table to fetch
     * @param {string} searchId - Optional search parameter
     */
    const fetchTableData = useCallback(async (table, searchId = '') => {
        setError(null);
        try {
            const url = searchId
                ? `${backendURL}/api/v1/searchName/${table}/${encodeURIComponent(searchId)}`
                : `${backendURL}/api/v1/data?tableName=${table}&columns=${columnName}`;
                
            const response = await fetch(url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const results = await response.json();

            // Only update state if the current table hasn't changed during the fetch
            if (currentTableRef.current === table) {
                setSearchResults(results.length > 0 ? results : []);
                if (results.length === 0) setError('No results found.');
            }
        } catch (error) {
            if (currentTableRef.current === table) {
                setError(`Error fetching data: ${error.message}`);
            }
        } finally {
            if (currentTableRef.current === table) {
                setLoading(false);
            }
        }
    }, [backendURL, columnName]);

    // Debounce the fetch operation to prevent excessive API calls
    const debouncedFetchTableData = useCallback(
        debounce(fetchTableData, 300),
        [fetchTableData]
    );

    // Trigger data fetch when search params or table changes
    useEffect(() => {
        debouncedFetchTableData(tableName, searchParams.id);
    }, [tableName, searchParams.id, debouncedFetchTableData]);

    // ================ Event Handlers ================

    /**
     * Handles table switching
     * @param {string} newTableName - The name of the table to switch to
     */
    const handleTableChange = useCallback((newTableName) => {
        if (newTableName !== tableName) {
            setLoading(true);
            setTableName(newTableName);
            setSearchParams({ id: '' });
            setSelectedRow(null);
            setShowDetails(false);
            setError(null);
            fetchTableData(newTableName, '');
        }
    }, [tableName, fetchTableData]);

    /**
     * Handles search input changes
     * @param {Event} e - The input change event
     */
    const handleInputChange = (e) => {
        setSearchParams({ id: e.target.value });
    };

    /**
     * Handles row selection
     * @param {Object} person - The selected row data
     */
    const handleSelectPerson = (person) => {
        setSelectedRow(person);
        setShowDetails(true);
    };

    /**
     * Triggers a data refresh after save operations
     */
    const handleSave = () => {
        fetchTableData(tableName, searchParams.id);
    };

    /**
     * Toggles the add row form visibility
     */
    const toggleAddDbRow = () => {
        setShowAddDbRow((prevState) => !prevState);
    };

    /**
     * Toggles the menu open/closed state
     */
    const toggleMenu = () => setMenuOpen(!menuOpen);

    /**
     * Toggles the search input visibility on mobile
     */
    const handleSearchToggle = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    /**
     * Handles closing the search input and clearing the search
     */
    const handleCloseSearch = () => {
        setIsSearchVisible(false);
        setSearchParams({ id: '' });
    };

    // ================ Render Methods ================

    /**
     * Renders the search component
     * @returns {JSX.Element|null}
     */
    const renderSearch = () => {
        if (tableName !== "revenue") return null;

        return (
            <div ref={searchContainerRef} className="relative">
                {/* Search Icon for small/medium screens */}
                <div className="lg:hidden">
                    {!isSearchVisible ? (
                        <button
                            onClick={handleSearchToggle}                            
                            className="px-3 py-2 mr-3 rounded-md bg-green-100 hover:bg-green-200 dark:bg-slate-500 dark:hover:bg-slate-400"
                            aria-label="Toggle search"
                        >
                            <FaSearch className="w-5 h-5" />
                        </button>
                    ) : null}
                </div>

                {/* Expanded Search Input */}
                <div className={`${
                    isSearchVisible 
                        ? 'absolute right-0 top-0 w-64 z-50'
                        : 'hidden lg:relative lg:block lg:w-auto'
                }`}>
                    <div className="flex items-center">
                        <input
                            ref={searchInputRef}
                            type="text"
                            value={searchParams.id}
                            onChange={handleInputChange}
                            placeholder="Search..."
                            className="w-full px-4 py-2 text-sm border rounded-l-md dark:bg-zinc-700 dark:border-zinc-600 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                            aria-label="Search input"
                        />
                        {isSearchVisible && (
                            <button
                                onClick={handleCloseSearch}
                                className="p-2 text-gray-500 bg-white border border-l-0 rounded-r-md dark:bg-zinc-700 dark:border-zinc-600 dark:text-gray-300 hover:text-gray-700 dark:hover:text-white"
                                aria-label="Close search"
                            >
                                <FaTimes className="w-5 h-5" />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    // ================ Main Render ================
    return (
        <div className="relative w-full max-w-[100%] md:max-w-[97%] xl:max-w-[80%] mt-5 lg:mt-10 shadow-2xl lg:mx-auto">
            {/* Header Section */}
            <div className="flex flex-wrap justify-between items-center rounded-t-xl dark:bg-zinc-700">
                {/* Table Menu */}
                <div ref={menuRef}>
                    <TableMenu
                        updateSelectedTable={handleTableChange}
                        selectedTable={tableName}
                        loading={false}
                        menuOpen={menuOpen}
                        toggleMenu={toggleMenu}
                    />
                </div>

                {/* Controls Section */}
                <div className='m-3 flex items-center'>
                    <button
                        onClick={toggleAddDbRow}
                        className="px-3 py-2 mr-3 rounded-md bg-green-100 hover:bg-green-200 dark:bg-slate-500 dark:hover:bg-slate-400"
                        aria-label="Add new row"
                    >
                        {/* + */}
                        <FaPlus className="w-5 h-5" />
                    </button>
                    {renderSearch()}
                </div>
            </div>

            {/* Add Row Form */}
            {showAddDbRow && (
                <AddDbRow
                    tableName={tableName}
                    backendURL={backendURL}
                    onClose={() => setShowAddDbRow(false)}
                    onSave={handleSave}
                />
            )}

            {/* Loading State */}
            {loading && (
                <div className='dark:bg-gray-400'>
                    <MotorbikeLoader />
                </div>
            )}

            {/* Error State */}
            {error && (
                <div role="alert" className="text-red-600 p-4">
                    Error! {error}
                </div>
            )}

            {/* Table Display */}
            {!loading && !error && searchResults.length > 0 && (
                <div className="w-full overflow-x-auto">
                    <DisplayTable
                        key={`${tableName}-${searchResults.length}`}
                        data={searchResults}
                        onRowSelect={handleSelectPerson}
                        tableName={tableName}
                    />
                </div>
            )}

            {/* Detail View */}
            {showDetails && selectedRow && (
                <DetailCard
                    backendURL={backendURL}
                    tableName={tableName}
                    rowId={selectedRow.id}
                    onClose={() => setShowDetails(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default MainTable;