import React, { useState, useEffect } from 'react';

const AddDbRow = ({ tableName, backendURL, onClose, onSave }) => {
    const [schema, setSchema] = useState([]);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Define columns to exclude for specific tables
    const exclusionColumns = {
        revenue: ['id', 'total_days', 'total_pay', 'active', 'km_finish'],
        expense: ['id', 'total_cost'],
        maintenance: ['id']
    };

    useEffect(() => {
        const fetchSchema = async () => {
            setLoading(true);
            try {
                const url = `${backendURL}/api/v1/table/${tableName}`;
                console.log(url);
                const response = await fetch(url);
                if (!response.ok) throw new Error('Failed to fetch table schema');
                const schemaData = await response.json();

                // Exclude specific columns based on the table name
                const excludedColumns = exclusionColumns[tableName] || [];
                const filteredSchema = schemaData.filter(column => !excludedColumns.includes(column));

                // Initialize form data for the remaining columns
                const initialFormData = filteredSchema.reduce((acc, column) => {
                    acc[column] = null; // Initialize each column name with null for placeholders
                    return acc;
                }, {});

                setFormData(initialFormData);
                setSchema(filteredSchema);
            } catch (error) {
                setError(`Error fetching schema: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        if (tableName) fetchSchema();
    }, [tableName, backendURL]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    async function submitFormData({ endpoint, method = 'POST', body }) {
        try {
            const headers = { 'Content-Type': 'application/json' };
            const response = await fetch(endpoint, {
                method,
                headers,
                body: JSON.stringify(body),
            });
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
            }
            return await response.json();
        } catch (error) {
            console.error('Error submitting form:', error);
            throw error;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        try {
            const result = await submitFormData({
                endpoint: `${backendURL}/api/v1/data`, // Ensure this matches your backend route
                body: { tableName, data: formData }   // Pass tableName and formData as the request body
            });

            console.log('Form submitted successfully:', result);
            alert('Form submitted successfully!');

            // Clear form data after submission
            setFormData({});

            if (onSave) onSave(); // Notify parent component to refresh data
            if (onClose) onClose(); // Close the modal
        } catch (error) {
            setError(`Error submitting form: ${error.message}`);
        }
    };

    if (loading) return <p>Loading schema...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 rounded-lg shadow-lg w-[90%] max-w-md max-h-[80vh] overflow-y-auto">
                <div className="modal-content bg-slate-50 dark:bg-gray-700 rounded-lg overflow-hidden">
                    <div className="flex justify-between items-center bg-green-200 dark:bg-teal-600 dark:text-gray-200 px-4 py-3 rounded-t-lg">
                        <h3 className="text-xl font-semibold mb-0">
                            Add New Row To {tableName.charAt(0).toUpperCase() + tableName.slice(1)}
                        </h3>
                        <button onClick={onClose} className="text-gray-800 dark:text-gray-200 hover:text-gray-600 focus:outline-none">
                            X
                        </button>
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-4 px-4 py-2">
                        {schema.map(column => (
                            <div className="flex flex-col mb-3" key={column}>
                                <label className="text-gray-700 dark:text-gray-300 text-sm font-semibold mb-1">
                                    {column.replace(/_/g, ' ').charAt(0).toUpperCase() + column.replace(/_/g, ' ').slice(1)}
                                </label>
                                <input
                                    type="text"
                                    name={column}  // This should match the column name in your schema
                                    value={formData[column] || ''}
                                    onChange={handleChange}
                                    placeholder={column.replace(/_/g, ' ').toUpperCase()}  // Use column name as placeholder
                                    className="input text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-600 border border-gray-300 dark:border-gray-500 rounded-md py-2 px-3 leading-tight focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                />
                            </div>
                        ))}

                        <div className="flex justify-end mt-4">
                            <button
                                type="submit"
                                className="bg-green-500 hover:bg-green-600 dark:bg-teal-600 dark:hover:bg-teal-700 dark:text-gray-200 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition-colors duration-200"
                            >
                                Submit
                            </button>
                            <button
                                onClick={onClose}
                                className="bg-gray-500 hover:bg-gray-600 dark:text-gray-200 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition-colors duration-200 ml-2"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
};

export default AddDbRow;
