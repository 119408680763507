import React from 'react';

const MotorbikeCard = () => {
    return (
        <div className="bg-gray-100 dark:bg-zinc-500 text-gray-600 dark:text-gray-300 shadow-md rounded-lg mr-8 ml-8 mb-2 p-4">
            <div className="flex flex-col sm:flex-row">
                <img src="/images/honda_beat.jpg" alt="Honda Beat" className="object-cover w-full sm:w-2/5 md:w-1/3 h-auto rounded-md"/>
                <div className="p-4 sm:p-6 flex flex-col justify-center">
                    <h3 className="text-xl font-bold">Honda Beat</h3>
                    <p className="mt-2">
                        The Honda Beat is a stylish and efficient scooter, perfect for navigating through city streets or exploring the beautiful landscapes of Siargao. With its 110cc engine, it offers a comfortable and enjoyable riding experience.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MotorbikeCard;


