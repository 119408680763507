import React from 'react';
import LineChart from './LineChart';
import DiagramChart from './DiagramChart';
import PieChart from './PieChart';
import CardData from './CardData';

const DashBoard = () => {
  return (
    <div className="p-1 md:p-2 lg:p-2 w-full max-w-screen-xl mx-auto justify-between">
      <CardData />  
    
      {/* Charts Section */}
      <div className="mt-2 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <LineChart />
        <DiagramChart />
        <PieChart />
      </div>

      
    </div>
  );
};

export default DashBoard;
