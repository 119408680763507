import React from 'react';
import Lottie from 'react-lottie';
import motorbikeAnimation from './motorbikeAnimation.json'

const MotorbikeLoader = () => {
    const defaultOptions = {
        loop: true, // The animation will loop indefinitely
        autoplay: true, // Starts playing automatically
        animationData: motorbikeAnimation, // JSON animation data
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice" // Ensures the animation stays centered
        }
    };

    return (
        <div className="motorbike-loader">
            <Lottie options={defaultOptions} height={300} width={300} />
        </div>
    );
};

export default MotorbikeLoader;
