// `${backendUrl}api/v1/data?tableName=revenue&columns=start_date,end_date,php,total_pay`

import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const options = {
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'left',
  },
  colors: ['#3C50E0', '#80CAEE'],
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    height: 350,
    type: 'area',
    dropShadow: {
      enabled: true,
      color: '#623CEA14',
      top: 10,
      blur: 4,
      left: 0,
      opacity: 0.1,
    },
    toolbar: {
      show: false,
    },
  },
  responsive: [
    {
      breakpoint: 1024,
      options: {
        chart: {
          height: 300,
        },
      },
    },
    {
      breakpoint: 1366,
      options: {
        chart: {
          height: 350,
        },
      },
    },
  ],
  stroke: {
    width: [2, 2],
    curve: 'straight',
  },
  grid: {
    xaxis: {
      lines: { show: true },
    },
    yaxis: {
      lines: { show: true },
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 4,
    colors: '#fff',
    strokeColors: ['#3056D3', '#80CAEE'],
    strokeWidth: 3,
  },
  xaxis: {
    type: 'category',
    categories: [
      'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb',
      'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
    ],
    axisBorder: { show: false },
    axisTicks: { show: false },
  },
  yaxis: {
    title: { style: { fontSize: '0px' } },
    min: 0,
    max: 100,
  },
};

const LineChart = () => {
  const [state] = useState({
    series: [
      {
        name: 'Monthly Revenue',
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 45],
      },
      // {
      //   name: 'Product Two',
      //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 51],
      // },
    ],
  });

  return (
    <div className="col-span-12 rounded-md border border-stroke bg-white px-2 pt-4 shadow-default sm:px-8 dark:border-0 dark:bg-gray-800">

      {/* Header Section */}
      <div className="flex flex-wrap items-center justify-between gap-4 sm:gap-6">
        <div className="flex flex-wrap gap-4">
          <div className="flex items-center">
            <span className="h-4 w-4 rounded-full border border-primary mr-2 flex items-center justify-center">
              <span className="h-2.5 w-2.5 rounded-full bg-primary"></span>
            </span>
            <div>
              <p className="text-sm font-semibold text-primary dark:text-white">Total Revenue</p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                12.04.2022 - 12.05.2022
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <span className="h-4 w-4 rounded-full border border-secondary mr-2 flex items-center justify-center">
              <span className="h-2.5 w-2.5 rounded-full bg-secondary"></span>
            </span>
            <div>
              <p className="text-sm font-semibold text-secondary dark:text-white">Total Sales</p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                12.04.2022 - 12.05.2022
              </p>
            </div>
          </div>
        </div>
        {/* Time Range Buttons */}
        <div className="flex items-center space-x-2">
          <button className="rounded bg-white dark:bg-gray-400 px-4 py-1 text-xs font-medium shadow hover:shadow-md dark:bg-boxdark dark:text-white">
            Day
          </button>
          <button className="rounded px-4 py-1 text-xs font-medium text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-800">
            Week
          </button>
          <button className="rounded px-4 py-1 text-xs font-medium text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-800">
            Month
          </button>
        </div>
      </div>

      {/* Chart */}
      <div id="chartOne" className="mt-6">
        <ReactApexChart
          options={{
            ...options,
            grid: {
              ...options.grid,
              borderColor: 'var(--tw-border-color)', // Adapts to dark mode
            },
            chart: {
              ...options.chart,
              foreColor: 'var(--tw-text-color)', // Adapts to dark mode
            },
          }}
          series={state.series}
          type="area"
          height={350}
        />
      </div>
    </div>
  );
};

export default LineChart;

// import React, { useState, useEffect } from 'react';
// import ReactApexChart from 'react-apexcharts';
// import {
//   parseISO,
//   addDays,
//   startOfMonth,
//   endOfMonth,
//   differenceInDays,
//   isBefore,
//   format,
// } from 'date-fns';
// import { useURL } from '../contexts/URLContext';

// const options = {
//   legend: {
//     show: false,
//     position: 'top',
//     horizontalAlign: 'left',
//   },
//   colors: ['#3C50E0', '#80CAEE'],
//   chart: {
//     fontFamily: 'Satoshi, sans-serif',
//     height: 350,
//     type: 'area',
//     dropShadow: {
//       enabled: true,
//       color: '#623CEA14',
//       top: 10,
//       blur: 4,
//       left: 0,
//       opacity: 0.1,
//     },
//     toolbar: {
//       show: false,
//     },
//   },
//   responsive: [
//     {
//       breakpoint: 1024,
//       options: {
//         chart: {
//           height: 300,
//         },
//       },
//     },
//     {
//       breakpoint: 1366,
//       options: {
//         chart: {
//           height: 350,
//         },
//       },
//     },
//   ],
//   stroke: {
//     width: [2, 2],
//     curve: 'straight',
//   },
//   grid: {
//     xaxis: {
//       lines: { show: true },
//     },
//     yaxis: {
//       lines: { show: true },
//     },
//   },
//   dataLabels: {
//     enabled: false,
//   },
//   markers: {
//     size: 4,
//     colors: '#fff',
//     strokeColors: ['#3056D3', '#80CAEE'],
//     strokeWidth: 3,
//   },
//   xaxis: {
//     type: 'category',
//     categories: [],
//     axisBorder: { show: false },
//     axisTicks: { show: false },
//   },
//   yaxis: {
//     title: { style: { fontSize: '0px' } },
//     min: 0,
//     max: 100,
//   },
// };

// const LineChart = () => {
//   const backendUrl = useURL();
//   const [state, setState] = useState({
//     series: [
//       {
//         name: 'Monthly Revenue',
//         data: [],
//       },
//     ],
//     xaxisCategories: [],
//   });

//   // Function to calculate monthly revenue
//   const calculateMonthlyRevenue = (data) => {
//     const monthlyRevenue = {};

//     data.forEach(({ start_date, end_date, php }) => {
//       let currentStartDate = parseISO(start_date);
//       const currentEndDate = parseISO(end_date);
//       const dailyRate = php;

//       while (isBefore(currentStartDate, currentEndDate)) {
//         const monthKey = format(currentStartDate, 'MMM yyyy');
//         const daysInMonth = differenceInDays(
//           isBefore(endOfMonth(currentStartDate), currentEndDate)
//             ? endOfMonth(currentStartDate)
//             : currentEndDate,
//           currentStartDate
//         ) + 1;

//         monthlyRevenue[monthKey] = (monthlyRevenue[monthKey] || 0) + daysInMonth * dailyRate;

//         // Move to the next month
//         currentStartDate = addDays(endOfMonth(currentStartDate), 1);
//       }
//     });

//     return monthlyRevenue;
//   };

//   // Fetch data from API and update chart state
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           `${backendUrl}/api/v1/data?tableName=revenue&columns=start_date,end_date,php,total_pay`
//         );
//         const data = await response.json();
//         console.log('API Response:', data);

//         const monthlyRevenue = calculateMonthlyRevenue(data);
//         const months = Object.keys(monthlyRevenue);
//         const revenueData = Object.values(monthlyRevenue);

//         setState({
//           series: [
//             {
//               name: 'Monthly Revenue',
//               data: revenueData,
//             },
//           ],
//           xaxisCategories: months,
//         });
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [backendUrl]);

//   return (
//     <div className="col-span-12 rounded-md border border-stroke bg-white px-2 pt-4 shadow-default sm:px-8 dark:border-0 dark:bg-gray-800">
//       {/* Header Section */}
//       <div className="flex flex-wrap items-center justify-between gap-4 sm:gap-6">
//         <div className="flex flex-wrap gap-4">
//           <div className="flex items-center">
//             <span className="h-4 w-4 rounded-full border border-primary mr-2 flex items-center justify-center">
//               <span className="h-2.5 w-2.5 rounded-full bg-primary"></span>
//             </span>
//             <div>
//               <p className="text-sm font-semibold text-primary dark:text-white">Total Revenue</p>
//               <p className="text-xs text-gray-500 dark:text-gray-400">12.04.2022 - 12.05.2022</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Chart */}
//       <div id="chartOne" className="mt-6">
//         <ReactApexChart
//           options={{
//             ...options,
//             xaxis: {
//               ...options.xaxis,
//               categories: state.xaxisCategories,
//             },
//           }}
//           series={state.series}
//           type="area"
//           height={350}
//         />
//       </div>
//     </div>
//   );
// };

// export default LineChart;
