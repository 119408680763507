import React, { createContext, useContext } from 'react';

// Create the context
const URLContext = createContext();

// Export a custom hook to easily access the context
export const useURL = () => useContext(URLContext);

// Create a provider component
export const URLProvider = ({ children }) => {
    // const backendURL = "http://localhost:8000";
    const backendURL = "https://psychic-bliss-423410-u0.ew.r.appspot.com";

    return (
        <URLContext.Provider value={backendURL}>
            {children}
        </URLContext.Provider>
    );
};
