// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import LandingPage from './autonticate/LandingPage';
// import LoginPage from './autonticate/LoginPage';
// import RegisterPage from './autonticate/Register';
// import PrivateRoute from './autonticate/PrivateRoute';
// import Dashboard from './components/Dashboard';
// import ToolBar from './components/ToolBar';
// import MotorbikeCard from './components/MotorbikeCard';
// import Events from './calendarView/Events';
// import { URLProvider } from './contexts/URLContext';
// import { DarkModeProvider } from './contexts/DarkModeContext';
// import MainTable from './table/MainTable';


// const App = () => {
//     return (
//         <div className="bg-white dark:bg-zinc-600 min-h-screen flex justify-center">
//             <div className="w-full flex flex-col">
//                 <DarkModeProvider>
//                     <URLProvider>
//                         <Router>
//                             <ToolBar />
//                             <Routes>
//                                 <Route path="/" element={<LandingPage />} />
//                                 <Route path="/login" element={<LoginPage />} />
//                                 <Route path="/register" element={<RegisterPage />} />
//                                 <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
//                                 <Route path="/bike" element={<MotorbikeCard />} />
//                                 {/* <Route path="/Calendar" element={<Events />} /> */}
//                                 <Route path="/Calendar" element={<PrivateRoute element={Events} />} />
//                                 {/* <Route path="/Table" element={<MainTable />} /> */}
//                                 <Route path="/Table" element={<PrivateRoute element={MainTable} />} />

//                             </Routes>
//                         </Router>
//                     </URLProvider>
//                 </DarkModeProvider>
//             </div>
//         </div>
//     );

// };

// export default App;

import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Named import for jwtDecode
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './autonticate/LandingPage';
import LoginPage from './autonticate/LoginPage';
import PrivateRoute from './autonticate/PrivateRoute';
import ToolBar from './components/ToolBar';
import { URLProvider } from './contexts/URLContext';
import { DarkModeProvider } from './contexts/DarkModeContext';
import RegisterPage from './autonticate/Register';
import MainTable from './table/MainTable';
import MotorbikeCard from './components/MotorbikeCard';
import Events from './calendarView/Events';
import DashBoard from './dashBoard/dashBoard';

const App = () => {
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        let sessionTimeout;

        const resetSessionTimeout = () => {
            clearTimeout(sessionTimeout);
            sessionTimeout = setTimeout(() => {
                localStorage.removeItem('authToken'); // Remove token after timeout
                window.location.href = '/login'; // Redirect to login page
            }, 15 * 60 * 1000); // 15 minutes
        };

        if (token) {
            try {
                const decodedToken = jwtDecode(token); // Use named import jwtDecode
                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    console.log('Token is expired');
                    localStorage.removeItem('authToken'); // Remove expired token
                    window.location.href = '/login'; // Redirect to login page
                } else {
                    console.log('Token is valid');

                    // Set session timeout for inactivity
                    sessionTimeout = setTimeout(() => {
                        localStorage.removeItem('authToken'); // Remove token after timeout
                        window.location.href = '/login'; // Redirect to login page
                    }, 15 * 60 * 1000); // 15 minutes

                    // Reset timeout on user activity
                    window.addEventListener('mousemove', resetSessionTimeout);
                    window.addEventListener('keypress', resetSessionTimeout);
                }
            } catch (error) {
                console.log('Invalid token format', error);
            }
        }

        // Cleanup when component is unmounted
        return () => {
            window.removeEventListener('mousemove', resetSessionTimeout);
            window.removeEventListener('keypress', resetSessionTimeout);
            clearTimeout(sessionTimeout);
        };
    }, []);

    return (
        <div className="bg-white dark:bg-zinc-600 min-h-screen flex justify-center">
            <div className="w-full flex flex-col">
                <DarkModeProvider>
                    <URLProvider>
                        <Router>
                            <ToolBar />
                            <Routes>
                                <Route path="/" element={<LandingPage />} />
                                <Route path="/login" element={<LoginPage />} />
                                {/* <Route path="/register" element={<RegisterPage />} /> */}
                                {/* <Route path="/dashboard" element={<PrivateRoute element={RegisterPage} />} /> */}
                                
                                <Route path="/dashboardfold" element={<PrivateRoute element={DashBoard} />} />
                                <Route path="/bike" element={<MotorbikeCard />} />
                                {/* <Route path="/Calendar" element={<Events />} /> */}
                                <Route path="/Calendar" element={<PrivateRoute element={Events} />} />
                                {/* <Route path="/Table" element={<MainTable />} /> */}
                                <Route path="/Table" element={<PrivateRoute element={MainTable} />} />
                            </Routes>
                        </Router>
                    </URLProvider>
                </DarkModeProvider>
            </div>
        </div>
    );
};

export default App;
