import React from 'react';
import { FaMotorcycle, FaCalendarAlt, FaDollarSign, FaListAlt } from 'react-icons/fa';
import { RiNumbersFill } from "react-icons/ri";
import { GrMoney } from "react-icons/gr";


const DefaultCard = ({ personData, index, renderField }) => {
    // Map specific keys to icons
    const keyIcons = {
        bike: <FaMotorcycle className="text-4xl text-blue-500 dark:text-blue-300 mr-4" />,
        type: <FaListAlt className="text-4xl text-green-500 dark:text-green-300 mr-4" />,
        date: <FaCalendarAlt className="text-4xl text-purple-500 dark:text-purple-300 mr-4" />,
        cost: <FaDollarSign className="text-4xl text-yellow-500 dark:text-yellow-300 mr-4" />,
        description: <FaListAlt className="text-4xl text-gray-500 dark:text-gray-300 mr-4" />,
        amount: <RiNumbersFill className="text-4xl text-green-500 dark:text-green-300 mr-4" />,
        cost_per_1: <GrMoney className="text-4xl text-yellow-500 dark:text-yellow-300 mr-4" />,
        total_cost: <FaDollarSign className="text-4xl text-orange-500 dark:text-orange-300 mr-4" />,
        date_of_purchase: <FaCalendarAlt className="text-4xl text-purple-500 dark:text-purple-300 mr-4" />,
    };

    return (
        <div className="bg-gradient-to-b from-blue-50 to-blue-100 dark:from-[#1b233d] dark:to-[#141a2e] text-gray-800 dark:text-[#aadef3b8] rounded-xl p-6 shadow-md hover:shadow-lg transition-all">
            <div className="space-y-6">
                {Object.keys(personData)
                    .filter((key) => key !== 'id')
                    .map((key) => (
                        <div
                            key={key}
                            className="flex items-center bg-gradient-to-b from-white to-purple-50 dark:from-[#2c2f47] dark:to-[#1b233d] rounded-lg shadow-lg p-6 w-full"
                        >
                            {keyIcons[key] || (
                                <div className="w-10 h-10 mr-4 bg-gray-300 rounded-full dark:bg-gray-600"></div>
                            )}
                            <div>
                                <div className="text-xl font-bold text-gray-800 dark:text-gray-200 capitalize">
                                    {key.replace(/_/g, ' ')}
                                </div>
                                <div className="flex items-center space-x-2 mt-2 text-md font-bold text-gray-500 dark:text-gray-300">
                                    {renderField(key, personData[key], index)}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default DefaultCard;
