// import React, { useState, useEffect } from 'react';
// import { FaRegEdit, FaTimes } from 'react-icons/fa';

// import RevenueCard from './RevenueCard';
// import DefaultCard from './DefaultCard';

// const DetailCard = ({ backendURL, tableName, rowId, onClose, onSave }) => {
//     const [editMode, setEditMode] = useState(false);
//     const [editedPerson, setEditedPerson] = useState({});
//     const [originalPerson, setOriginalPerson] = useState({});

//     useEffect(() => {
//         if (rowId) {
//             fetchData(tableName, rowId);
//         }
//     }, [rowId]);

//     const fetchData = async (tableName, id) => {
//         try {
//             const query = `${backendURL}/api/v1/search/${tableName}/${id}`;
//             const response = await fetch(query);
//             if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
//             const data = await response.json();
//             setOriginalPerson(data);
//             setEditedPerson(data);
//         } catch (error) {
//             console.error(`Error fetching data for table ${tableName}:`, error);
//         }
//     };

//     const handleChange = (e, index) => {
//         const { name, value } = e.target;
//         const updatedPerson = [...editedPerson];
//         updatedPerson[index] = {
//             ...updatedPerson[index],
//             [name]: value,
//         };
//         setEditedPerson(updatedPerson);
//     };

//     const getModifiedFields = () => {
//         const modifiedFields = {};
//         const editedData = editedPerson[0];
//         const originalData = originalPerson[0];
//         Object.keys(editedData).forEach((key) => {
//             if (editedData[key] !== originalData[key] && key !== 'id') {
//                 modifiedFields[key] = editedData[key];
//             }
//         });
//         return Object.keys(modifiedFields).length ? modifiedFields : null;
//     };

//     const saveChangesToBackend = async () => {
//         const modifiedFields = getModifiedFields();

//         if (modifiedFields) {
//             try {
//                 const response = await fetch(`${backendURL}/api/v1/update/${tableName}/${rowId}`, {
//                     method: 'PUT',
//                     headers: { 'Content-Type': 'application/json' },
//                     body: JSON.stringify(modifiedFields),
//                 });
//                 const result = await response.text();
//                 if (!response.ok) throw new Error(result);
//                 alert('Changes saved successfully!');
//                 if (onSave) onSave();
//                 onClose();
//             } catch (error) {
//                 console.error('Error saving changes:', error);
//                 alert('Error saving changes: ' + error.message);
//             }
//         }
//     };

//     const renderField = (key, value, index) => {
//         return editMode ? (
//             <div className='inline-block'>
//                 <input
//                     id={key}
//                     type="text"
//                     name={key}
//                     value={key.includes('date') ? value.split(' ')[0] : value}
//                     onChange={(e) => handleChange(e, index)}
//                     className="input text-gray-700 dark:text-[#aadef3b8] text-center bg-transparent border-b border-gray-300 dark:border-[#aadef3b8] w-full leading-normal focus:outline-none focus:border-blue-500 focus:ring-0 focus:border-b-2 cursor-text"
//                 />
//             </div>
//         ) : (
//             <p className="">{value = key.includes('date') ? value.split(' ')[0] : value}</p>
//         );
//     };

//     const renderCard = (personData, index) => {
//         if (tableName === 'revenue') {
//             return <RevenueCard personData={personData} index={index} renderField={renderField} />;
//         }
//         return <DefaultCard personData={personData} index={index} renderField={renderField} />;
//     };

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto">
//             <div className="absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full sm:w-11/12 lg:w-/12 max-w-screen-xl max-h-[80vh] overflow-y-auto">

//                 {/* Header */}
//                 <div className="sticky top-0 z-10 flex justify-between items-center dark:bg-[#1b233d] rounded-t-xl bg-blue-200 px-4 py-1">
//                     <h3 className="text-2xl text-gray-800 dark:text-[#bcdfeeb8] p-1.5 font-semibold mb-0">
//                     {/* {tableName.charAt(0).toUpperCase() + tableName.slice(1)} */}
//                     Card Details 
//                     </h3>
//                     <div className="m-2 flex flex-wrap gap-x-3">
//                         {!editMode && (
//                             <button
//                                 onClick={() => setEditMode(true)}
//                                 className="text-blue-500 font-bold focus:outline-none hover:text-blue-700">
//                                 <FaRegEdit size={35} className="transform transition-all duration-200 hover:scale-110" />
//                             </button>
//                         )}
//                         <button
//                             onClick={onClose}
//                             className="text-4xl font-normal text-gray-600 hover:text-gray-700 focus:outline-none transform hover:scale-110">
//                             <FaTimes size={35} />
//                         </button>
//                     </div>
//                 </div>

//                 {/* Form or View */}
//                 {editMode ? (
//                     <form onSubmit={(e) => e.preventDefault()}>
//                         {Array.isArray(editedPerson) && editedPerson.length > 0 ? (
//                             editedPerson.map((personData, index) => renderCard(personData, index))
//                         ) : (
//                             <p>No data available</p>
//                         )}
//                         <div className="flex justify-end mt-4">
//                             <button
//                                 onClick={() => setEditMode(false)}
//                                 className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition-colors duration-200 mr-2"
//                             >
//                                 Cancel
//                             </button>
//                             <button
//                                 type="submit"
//                                 onClick={saveChangesToBackend}
//                                 className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition-colors duration-200"
//                             >
//                                 Save Changes
//                             </button>
//                         </div>
//                     </form>
//                 ) : (
//                     <div className="">
//                         {Array.isArray(editedPerson) && editedPerson.length > 0 ? (
//                             editedPerson.map((personData, index) => renderCard(personData, index))
//                         ) : (
//                             <p>No data available</p>
//                         )}
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default DetailCard;


import React, { useState, useEffect } from 'react';
import { FaRegEdit, FaTimes } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import RevenueCard from './RevenueCard';
import DefaultCard from './DefaultCard';

const DetailCard = ({ backendURL, tableName, rowId, onClose, onSave }) => {
    const [editMode, setEditMode] = useState(false);
    const [editedPerson, setEditedPerson] = useState({});
    const [originalPerson, setOriginalPerson] = useState({});

    useEffect(() => {
        if (rowId) {
            fetchData(tableName, rowId);
        }
    }, [rowId]);

    const fetchData = async (tableName, id) => {
        try {
            const query = `${backendURL}/api/v1/search/${tableName}/${id}`;
            const response = await fetch(query);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();

            // Format dates in the received data
            const formattedData = data.map(item => {
                const formattedItem = { ...item };
                Object.keys(formattedItem).forEach(key => {
                    if (isDateField(key) && formattedItem[key]) {
                        formattedItem[key] = formatDateTime(new Date(formattedItem[key]));
                    }
                });
                return formattedItem;
            });

            setOriginalPerson(formattedData);
            setEditedPerson(formattedData);
        } catch (error) {
            console.error(`Error fetching data for table ${tableName}:`, error);
        }
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedPerson = [...editedPerson];
        updatedPerson[index] = {
            ...updatedPerson[index],
            [name]: value,
        };
        setEditedPerson(updatedPerson);
    };

    const formatDateTime = (date) => {
        if (!date) return '';
        
        // Get only the date part and add default time
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        
        // Always return with 00:00:00.000
        return `${year}-${month}-${day} 00:00:00.000`;
    };

    const handleDateChange = (date, fieldName, index) => {
        const formattedDate = date ? formatDateTime(date) : '';
        const updatedPerson = [...editedPerson];
        updatedPerson[index] = {
            ...updatedPerson[index],
            [fieldName]: formattedDate,
        };
        setEditedPerson(updatedPerson);
    };

    const getModifiedFields = () => {
        const modifiedFields = {};
        const editedData = editedPerson[0];
        const originalData = originalPerson[0];
        Object.keys(editedData).forEach((key) => {
            if (editedData[key] !== originalData[key] && key !== 'id') {
                modifiedFields[key] = editedData[key];
            }
        });
        return Object.keys(modifiedFields).length ? modifiedFields : null;
    };

    const saveChangesToBackend = async () => {
        const modifiedFields = getModifiedFields();

        if (modifiedFields) {
            try {
                const response = await fetch(`${backendURL}/api/v1/update/${tableName}/${rowId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(modifiedFields),
                });
                const result = await response.text();
                if (!response.ok) throw new Error(result);
                alert('Changes saved successfully!');
                if (onSave) onSave();
                onClose();
            } catch (error) {
                console.error('Error saving changes:', error);
                alert('Error saving changes: ' + error.message);
            }
        }
    };

    const isDateField = (key) => {
        return key.toLowerCase().includes('date');
    };

    const renderField = (key, value, index) => {
        if (isDateField(key)) {
            return editMode ? (
                <div className="inline-block w-full">
                    <DatePicker
                        selected={value ? new Date(value.split(' ')[0]) : null}
                        onChange={(date) => handleDateChange(date, key, index)}
                        dateFormat="yyyy-MM-dd"
                        className="input bg-cyan-200 dark:bg-slate-600 marker:text-gray-700 dark:text-[#aadef3b8] text-center bg-transparent border-b border-gray-300 dark:border-[#aadef3b8] w-full leading-normal focus:outline-none focus:border-blue-500 focus:ring-0 focus:border-b-2 cursor-text"
                        placeholderText="Select date"
                        
                    />
                </div>
            ) : (
                // <p className="">{value}</p>
                <p className="">{value = key.includes('date') ? value.split(' ')[0] : value}</p>
            );
        }

        return editMode ? (
            <div className="inline-block">
                <input
                    id={key}
                    type="text"
                    name={key}
                    value={value}
                    onChange={(e) => handleChange(e, index)}
                    className="input text-gray-700 dark:text-[#aadef3b8] text-center bg-transparent border-b border-gray-300 dark:border-[#aadef3b8] w-full leading-normal focus:outline-none focus:border-blue-500 focus:ring-0 focus:border-b-2 cursor-text"
                />
            </div>
        ) : (
            <p className="">{value}</p>
        );
    };

    const renderCard = (personData, index) => {
        if (tableName === 'revenue') {
            return <RevenueCard personData={personData} index={index} renderField={renderField} />;
        }
        return <DefaultCard personData={personData} index={index} renderField={renderField} />;
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full sm:w-11/12 lg:w-/12 max-w-screen-xl max-h-[80vh] overflow-y-auto">
                <div className="sticky top-0 z-10 flex justify-between items-center dark:bg-[#1b233d] rounded-t-xl bg-blue-200 px-4 py-1">
                    <h3 className="text-2xl text-gray-800 dark:text-[#bcdfeeb8] p-1.5 font-semibold mb-0">
                        Card Details
                    </h3>
                    <div className="m-2 flex flex-wrap gap-x-3">
                        {!editMode && (
                            <button
                                onClick={() => setEditMode(true)}
                                className="text-blue-500 font-bold focus:outline-none hover:text-blue-700">
                                <FaRegEdit size={35} className="transform transition-all duration-200 hover:scale-110" />
                            </button>
                        )}
                        <button
                            onClick={onClose}
                            className="text-4xl font-normal text-gray-600 hover:text-gray-700 focus:outline-none transform hover:scale-110">
                            <FaTimes size={35} />
                        </button>
                    </div>
                </div>

                {editMode ? (
                    <form onSubmit={(e) => e.preventDefault()}>
                        {Array.isArray(editedPerson) && editedPerson.length > 0 ? (
                            editedPerson.map((personData, index) => renderCard(personData, index))
                        ) : (
                            <p>No data available</p>
                        )}
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => setEditMode(false)}
                                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition-colors duration-200 mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                onClick={saveChangesToBackend}
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition-colors duration-200"
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className="">
                        {Array.isArray(editedPerson) && editedPerson.length > 0 ? (
                            editedPerson.map((personData, index) => renderCard(personData, index))
                        ) : (
                            <p>No data available</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DetailCard;