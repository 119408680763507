import React, { useState } from 'react';
import { getISOWeek } from 'date-fns'; // Use date-fns to get the week number

const Calendar = ({ events }) => {
    const today = new Date();
    const [currentMonth, setCurrentMonth] = useState(today.getMonth());
    const [currentYear, setCurrentYear] = useState(today.getFullYear());

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const bikeColors = {
        1: 'bg-gray-500',
        2: 'bg-blue-500',
        3: 'bg-green-500',
        4: 'bg-red-500',
        5: 'bg-yellow-500',
        6: 'bg-indigo-500'
    };

    const getDaysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const generateDatesForMonth = (month, year) => {
        const daysInMonth = getDaysInMonth(month, year);
        let dates = [];
        for (let day = 1; day <= daysInMonth; day++) {
            dates.push(new Date(year, month, day));
        }
        return dates;
    };

    const getFirstDayOfMonth = (month, year) => {
        return new Date(year, month, 1).getDay();
    };

    const goToPreviousMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    };

    const goToNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    };

    const goToPreviousYear = () => {
        setCurrentYear(currentYear - 1);
    };

    const goToNextYear = () => {
        setCurrentYear(currentYear + 1);
    };

    const goToToday = () => {
        setCurrentMonth(today.getMonth());
        setCurrentYear(today.getFullYear());
    };

    const onPersonClick = (event) => {
        alert(`Person clicked: ${event.name}`);
    };

    // Track the weeks where events have already been rendered
    const displayedWeeks = new Set();

    const getSundayStartWeekNumber = (date) => {
        const dayOfWeek = date.getDay(); // 0 for Sunday, 6 for Saturday
    
        // Adjust the date to align with ISO week starting on Monday
        // Shift the date back by 1 day if it's Sunday (so ISO week treats it as part of the previous week)
        const adjustedDate = dayOfWeek === 0 ? new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1) : date;
    
        // Now use getISOWeek with the adjusted date
        return getISOWeek(adjustedDate);
    };

    const shouldDisplayEventThisWeek = (event, date) => {
        const weekNumber = getSundayStartWeekNumber(date); // Get week number for the date, start from sunday
        const eventKey = `${event.name}-${weekNumber}`;
        
        if (!displayedWeeks.has(eventKey)) {
            displayedWeeks.add(eventKey); // Mark event as displayed for this week
            return true; // Show event name and color
        }
        return false; // Only show color
    };

    const renderDate = (date) => {
        const isToday = date.toDateString() === today.toDateString();

        // Filter events that should be displayed on this date
        const dayEvents = events.filter(event =>
            date >= event.startDate && date <= event.endDate
        );

        return (
            <div
                key={date}
                className={`cursor-pointer pt-1.5 pb-1.5 shadow-sm rounded-md ${isToday ? 'bg-blue-400 dark:bg-gray-400 hover:bg-blue-600 text-white' : ''}`}
                style={{ minHeight: '20px' }}
            >
                <div className="lg:font-bold">{date.getDate()}</div>
                {/* Render events - bike renters */}
                <div className="grid grid-rows-5 gap-1 mt-1 mb-1 sm:mt-0.5 sm:mb-0.5">
                    {dayEvents.map((event, bikeIndex) => {
                        const displayName = shouldDisplayEventThisWeek(event, date);
                        const displayText = displayName ? `${event.name.split(' ')[0]}` : ""; // Only show the name once per week
                        // const displayText = `${event.name.split(' ')[0]}`; //see all the names. 

                        let colorClass = 'bg-black';
                        if (event.bike > 0) {
                            colorClass = bikeColors[event.bike];
                        }

                        let additionalClasses = '';
                        if (date.toDateString() === event.startDate.toDateString()) {
                            additionalClasses = ' rounded-l-md ml-2 lg:ml-1';
                        }
                        if (date.toDateString() === event.endDate.toDateString()) {
                            additionalClasses += ' rounded-r-md mr-2 lg:mr-1';
                        }

                        return (
                            <div
                                key={bikeIndex}
                                className={`text-xs text-white mt-1 p-1 font-bold ${colorClass} ${additionalClasses}`}
                                style={{
                                    gridRow: event.bike,
                                    minHeight: '20px'
                                }}
                                onClick={() => onPersonClick(event)}
                            >
                                {displayText.replace("null", "") || <span>&nbsp;</span>}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const dates = generateDatesForMonth(currentMonth, currentYear);
    const firstDayOfMonth = getFirstDayOfMonth(currentMonth, currentYear);
    const commonButtonStyles = "px-2 py-1 bg-gray-300 dark:bg-gray-500 hover:bg-gray-200";

    return (
        <div className="container mx-auto mt-5 lg:mt-10 text-gray-600 dark:text-gray-200 sm:w-[95%] md:w-[85%] lg:w-[80%] rounded-xl shadow-2xl">
            {/* Calendar navigation */}
            <div className="flex items-center mb-4">
                <button onClick={goToPreviousYear} className={`${commonButtonStyles} rounded-l`}>«</button>
                <button onClick={goToPreviousMonth} className={commonButtonStyles}>‹</button>
                <div className="text-center flex-grow font-bold ml-1 sm:text-sm md:text-lg lg:text-2xl">
                    <h2>{months[currentMonth]} {currentYear}</h2>
                </div>
                <button onClick={goToToday} className="px-2 py-1 bg-green-400 text-white rounded">Today</button>
                <button onClick={goToNextMonth} className={commonButtonStyles}>›</button>
                <button onClick={goToNextYear} className={`${commonButtonStyles} rounded-r`}>»</button>
            </div>
            <div className="mt-5 grid grid-cols-7 text-center gap-0 text-xs sm:text-sm">
                {daysOfWeek.map(day => (
                    <div key={day} className="font-bold p-2">
                        {day}
                    </div>
                ))}
                {/* Empty slots before the first day of the month */}
                {Array.from({ length: firstDayOfMonth }).map((_, index) => (
                    <div key={index}></div>
                ))}
                {/* Render current month's dates */}
                {dates.map(renderDate)}
            </div>
        </div>
    );
};

export default Calendar;
