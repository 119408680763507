import React, { useState, useEffect } from 'react';
import { isValid, differenceInCalendarDays } from 'date-fns';
import { TbPigMoney } from "react-icons/tb";
import { PiHandCoinsFill } from "react-icons/pi";
import { FaMotorcycle, FaCalendarAlt, FaDollarSign, FaUser } from 'react-icons/fa';
import { GrMoney } from "react-icons/gr";


const RevenueCard = ({ personData, index, renderField }) => {
    const startDate = personData.start_date ? new Date(personData.start_date.split(' ')[0]) : null;
    const endDate = personData.end_date ? new Date(personData.end_date.split(' ')[0]) : null;

    const [totalDays, setTotalDays] = useState(0);
    const [totalPay, setTotalPay] = useState(0);

    useEffect(() => {
        if (isValid(startDate) && isValid(endDate)) {
            const days = differenceInCalendarDays(endDate, startDate) + 1;
            setTotalDays(days);
            const pricePerDay = personData.php || 0;
            const discount = -personData.dis_add || 0;
            const pay = days * pricePerDay - discount;
            setTotalPay(pay);
            personData.total_pay = pay;
            personData.total_days = days;
        } else {
            setTotalDays(0);
            setTotalPay(0);
            personData.total_pay = 0;
        }
    }, [startDate, endDate, personData.php, personData.dis_add]);


    return (
        <div className="bg-blue-50 dark:bg-[#1b233d] rounded-b-xl p-6 shadow-md transition-all hover:shadow-lg">
            <div className="flex flex-col lg:flex-row justify-between gap-6">
                {/* Customer */}
                <div className="flex items-center bg-gradient-to-b from-white to-green-50 dark:from-[#2c2f47] dark:to-[#1b233d] rounded-lg shadow-lg p-6 w-full lg:w-auto">
                    <FaUser className="text-4xl text-green-500 dark:text-green-300 mr-4" />
                    <div>
                        <div className="flex items-center space-x-2 text-xl font-bold text-gray-800 dark:text-gray-200">
                            {renderField('family', personData.family, index)} {renderField('name', personData.name, index)}
                        </div>
                        <div className="flex items-center space-x-2 mt-2 text-md font-bold text-gray-500 dark:text-gray-300">
                            <span>Passport:</span>
                            {renderField('passport', personData.passport, index)}
                        </div>
                    </div>
                </div>

                {/* Dates */}
                <div className="flex items-center bg-gradient-to-b from-white to-purple-50 dark:from-[#2c2f47] dark:to-[#1b233d] rounded-lg shadow-lg p-6 w-full lg:w-auto">
                    <FaCalendarAlt className="text-4xl text-purple-500 dark:text-purple-300 mr-4" />
                    <div>
                        <div className="text-xl font-bold text-gray-800 dark:text-gray-200">Dates</div>
                        <div className="flex items-center space-x-2 mt-2 text-md font-bold text-gray-500 dark:text-gray-300">
                            {renderField('start_date', personData.start_date, index)}
                            <span>-</span>
                            {renderField('end_date', personData.end_date, index)}
                        </div>
                    </div>
                </div>

                {/* Bike and Kilometers */}
                <div className="flex items-center bg-gradient-to-b from-white to-blue-50 dark:from-[#2c2f47] dark:to-[#1b233d] rounded-lg shadow-lg p-6 w-full lg:w-auto">
                    <FaMotorcycle className="text-4xl text-blue-500 dark:text-blue-300 mr-4" />
                    <div>
                        <div className='flex items-center space-x-2 text-xl font-bold text-gray-800 dark:text-gray-200'>
                            <div className="text-xl font-bold text-gray-800 dark:text-gray-200">Bike</div>
                            {renderField('bike', personData.bike, index)}
                        </div>
                        <div className="flex items-center space-x-2 mt-2 text-md font-bold text-gray-500 dark:text-gray-300">
                            <span>{renderField('km_start', personData.km_start, index)}</span>
                            <span>-</span>
                            <span> {renderField('km_finish', personData.km_finish, index)}</span>
                            <span className='text-sm'>km</span>
                        </div>
                    </div>
                </div>
            </div>


            <hr className="my-6 border-t-2 border-gray-200 dark:border-gray-600" />

            {/* Totals Grid */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md dark:bg-[#2c2f47]">
                    <FaCalendarAlt className="text-xl text-yellow-300 dark:text-yellow-100 mb-1" />
                    <div className="text-xl font-bold text-blue-600 dark:text-blue-300">{totalDays}</div>
                    <div className="text-sm text-gray-600 dark:text-gray-400">Total Days</div>
                </div>
                <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md dark:bg-[#2c2f47]">
                    <PiHandCoinsFill className="text-xl text-blue-600 dark:text-blue-300 mb-1" />
                    <div className="text-xl font-bold text-green-600 dark:text-green-300">{renderField('php', personData.php, index)}</div>
                    <div className="text-sm text-gray-600 dark:text-gray-400">Price/Day</div>
                </div>

                {/* add and discount */}
                <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md dark:bg-[#2c2f47]">
                    {personData.dis_add >= 0 ? (
                        <GrMoney className="text-xl text-red-500 dark:text-red-300 mb-1" />
                    ) : (
                        <TbPigMoney className="text-xl text-green-500 dark:text-green-300 mb-1" />
                    )}
                    <div className={`text-xl font-bold ${personData.dis_add >= 0 ? 'text-green-600 dark:text-green-300' : 'text-red-600 dark:text-red-300'}`}>
                        {renderField('dis_add', personData.dis_add, index)}
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-400">
                        {personData.dis_add >= 0 ? 'Add' : 'Discount'}
                    </div>
                </div>

                <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md dark:bg-[#2c2f47]">
                    <FaDollarSign className="text-xl text-green-500 dark:text-green-300 mb-1" />
                    <div className="text-xl font-bold text-green-700 dark:text-green-400">{totalPay}</div>
                    <div className="text-sm text-gray-600 dark:text-gray-400">Total Cost</div>
                </div>
            </div>
        </div>
    );
};

export default RevenueCard;
