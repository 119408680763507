import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const options = {
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'donut',
  },
  colors: ['#3C50E0', '#6577F3', '#8FD0EF', '#0FADCF'],
  labels: ['Desktop', 'Tablet', 'Mobile', 'Unknown'],
  legend: {
    show: false,
    position: 'bottom',
  },
  plotOptions: {
    pie: {
      donut: {
        size: '65%',
        background: 'transparent',
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 2600,
      options: {
        chart: {
          width: 380,
        },
      },
    },
    {
      breakpoint: 640,
      options: {
        chart: {
          width: 200,
        },
      },
    },
  ],
};

const PieChart = () => {
  const [state, setState] = useState({
    series: [65, 34, 12, 56],
  });

  const handleReset = () => {
    setState({
      series: [65, 34, 12, 56],
    });
  };

  return (
    <div className="col-span-12 rounded-lg border border-stroke bg-white p-6 shadow-sm xl:col-span-6 dark:border-0 dark:bg-gray-800">

      {/* Header Section */}
      <div className="flex items-center justify-between mb-5">
        <div>
          <h4 className="text-lg font-semibold text-black dark:text-white">
            Visitors Analytics
          </h4>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Device distribution of visitors
          </p>
        </div>
        <div>
          <div className="relative">
            <select
              name="time-period"
              id="time-period"
              className="appearance-none rounded-md border border-gray-300 bg-gray-50 py-1.5 px-3 text-sm font-medium text-gray-700 shadow-sm outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300"
            >
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
            <span className="absolute top-1/2 right-3 -translate-y-1/2 pointer-events-none">
              <svg
                width="12"
                height="6"
                viewBox="0 0 12 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6 5L11 1"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      {/* Chart Section */}
      <div className="flex justify-center">
        <ReactApexChart
          options={options}
          series={state.series}
          type="donut"
        />
      </div>

      {/* Legend Section */}
      <div className="mt-6 grid grid-cols-2 gap-y-3">
        <div className="flex items-center">
          <span className="mr-2 block h-3 w-3 rounded-full bg-primary"></span>
          <p className="flex justify-between text-sm font-medium text-black dark:text-white w-full">
            <span>Desktop</span>
            <span>65%</span>
          </p>
        </div>
        <div className="flex items-center">
          <span className="mr-2 block h-3 w-3 rounded-full bg-[#6577F3]"></span>
          <p className="flex justify-between text-sm font-medium text-black dark:text-white w-full">
            <span>Tablet</span>
            <span>34%</span>
          </p>
        </div>
        <div className="flex items-center">
          <span className="mr-2 block h-3 w-3 rounded-full bg-[#8FD0EF]"></span>
          <p className="flex justify-between text-sm font-medium text-black dark:text-white w-full">
            <span>Mobile</span>
            <span>12%</span>
          </p>
        </div>
        <div className="flex items-center">
          <span className="mr-2 block h-3 w-3 rounded-full bg-[#0FADCF]"></span>
          <p className="flex justify-between text-sm font-medium text-black dark:text-white w-full">
            <span>Unknown</span>
            <span>56%</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
