import { useState, useEffect } from 'react';
import axios from 'axios';
import { useURL } from '../contexts/URLContext';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const backendURL = useURL();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get(`${backendURL}/check-auth`, {
            headers: { 'Authorization': `Bearer ${token}` },
            withCredentials: true,
          });
          setIsAuthenticated(response.status === 200);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  return { isAuthenticated, loading };
};

export default useAuth;
