import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDarkModeContext } from '../contexts/DarkModeContext';
import { FaMoon, FaSun, FaBars, FaTimes } from 'react-icons/fa';

const ToolBarStyle = `
  text-blue-500 hover:text-blue-600 dark:text-teal-200 dark:hover:text-teal-300 
  hover:underline p-1 md:mx-4 md:my-0
  transition-all duration-300 transform rounded-sm
  cursor-pointer focus:outline-none focus:scale-x-110 hover:scale-105
`;

const navLinks = [
  { to: "/", label: "Home" },
  // { to: "/contact", label: "Contact" },
  // { to: "/about", label: "About Us" },
  { to: "/bike", label: "Bikes" },
  { to: "/calendar", label: "Calendar" },
  { to: "/table", label: "Table" },
  { to: "/dashboardfold", label: "Dashbodrd" },
];

const ToolBar = () => {
  const { darkTheme, setDarkTheme } = useDarkModeContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  // Close menu on clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="sticky top-0 left-0 right-0 bg-white dark:bg-zinc-800 p-3 sm:mb-1 md:mb-2 shadow-md z-50">
      <div className="flex justify-between items-center">
      
        {/* Hamburger menu icon for mobile screens */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-gray-700 dark:text-gray-300 focus:outline-none">
            {menuOpen ? <FaTimes size={28} /> : <FaBars size={28} />}
          </button>
        </div>

        {/* Navigation links for larger screens */}
        <div className="hidden md:flex flex-row items-center space-l-2">
          {navLinks.map((link) => (
            <Link key={link.to} to={link.to} className={ToolBarStyle}>
              {link.label}
            </Link>
          ))}
        </div>

        {/* Dark mode toggle */}
        <div className="flex space-x-2 items-center">
          <DarkModeIcon darkTheme={darkTheme} setDarkTheme={setDarkTheme} />
        </div>
      </div>

      {/* Dropdown menu for small screens */}
      {menuOpen && (
        <div
          ref={menuRef}
          className="absolute top-full left-0 right-0 bg-white dark:bg-zinc-800 shadow-lg rounded-lg z-50"
        >
          <div className="flex flex-col space-y-2 p-4">
            {navLinks.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className={ToolBarStyle}
                onClick={() => setMenuOpen(false)}
              >
                {link.label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const DarkModeIcon = ({ darkTheme, setDarkTheme }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  const handleMode = () => setDarkTheme((prevMode) => !prevMode);

  const showToolTip = () => setShowTooltip(true);

  const hideToolTip = () => setShowTooltip(false);

  // Handle clicking outside to close the tooltip
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false); // Close tooltip when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Show the tooltip for 2 seconds
  const handleMouseEnter = () => {
    showToolTip();
    setTimeout(() => {
      hideToolTip();
    }, 2000); // Hide tooltip after 2 seconds
  };

  // For mobile: Show the tooltip on tap and hide it after 2 seconds
  const handleTouchStart = () => {
    showToolTip();
    setTimeout(() => {
      hideToolTip();
    }, 2000); // Hide tooltip after 2 seconds
  };

  return (
    <div className="relative inline-block">
      <span
        onClick={handleMode}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={hideToolTip}
        onTouchStart={handleTouchStart}  // Handle touch on mobile
        className="cursor-pointer"
      >
        {darkTheme ? (
          <FaSun size="30" className="text-gray-400" />
        ) : (
          <FaMoon size="30" className="text-gray-500" />
        )}
      </span>
      {showTooltip && (
        <div 
          ref={tooltipRef}
          className="absolute z-10 px-3 py-2 text-sm right-full font-medium text-white bg-gray-700 dark:bg-gray-600 rounded-lg shadow-sm opacity-100 transition-opacity duration-300"
        >
          {darkTheme ? "Light Mode" : "Dark Mode"}
        </div>
      )}
    </div>
  );
};

export default ToolBar;
