import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useURL } from '../contexts/URLContext';

const LandingPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  
  const backendURL = useURL();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Perform the API check here
      fetch(`${backendURL}/check-auth`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          if (response.ok) {
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false); // or handle it based on your specific error handling logic
          }
        })
        .catch(error => {
          console.error('Error checking auth status:', error);
          setIsLoggedIn(false); // Handle error scenario
        });
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center bg-cover bg-center">
      <div className="bg-white bg-opacity-90 p-4 md:p-8 rounded-lg shadow-md">
        <h1 className="text-4xl font-bold mb-4 text-center">Welcome to renting a bike in Siargao Philippines</h1>
        <p className="text-lg text-gray-600 mb-8 text-center">
          {isLoggedIn ? 'Start exploring our bike rental services.' : 'Login to start exploring our bike rental services.'}
        </p>
        <div className="flex justify-center">
          {isLoggedIn ? (
            <Link to="/dashboard" className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg shadow-md inline-block transition duration-300 ease-in-out">
              Rent Bike
            </Link>
          ) : (
            <Link to="/login" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md inline-block transition duration-300 ease-in-out">
              Login
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
